import { create } from 'zoid/dist/zoid.frame';
import { ZalgoPromise } from 'zalgo-promise';
import { renderModal, triggerEscape } from './widget-modal';

const FULL_BASE_URL = new URL(__webpack_public_path__).origin;

export default function(options) {
    const defaults = {
        tag: 'shipearly-widget',
        url: `${FULL_BASE_URL}/widgets`,
        attributes: {
            iframe: {
                allow: `geolocation ${FULL_BASE_URL}`
            }
        },
        dimensions: {
            width: '100%',
            height: '100%'
        },
        props: {
            client_id: {
                type: 'string',
                required: true,
                queryParam: true
            },
            onEscape: {
                type: 'function',
                required: false
            },
        }
    };

    if (options.path !== undefined) {
        options.url = defaults.url + options.path.replace(/^\/?/, '/');
        options.path = undefined;
    }

    const title = options.title || '<!-- Empty -->';
    options.title = undefined;

    options.props = Object.assign({}, defaults.props, options.props || {})
    options = Object.assign({}, defaults, options)

    const zoidApp = create(options);

    // Listen for ESC key when zoidApp is declared inside component frame
    if (typeof window.xprops?.onEscape === 'function') {
        // Prevent duplicated event handlers by hoisting an external callback
        document.addEventListener('keyup', onEscKeyUp);
    }

    return function(props) {
        props.onEscape = (function(_parent) {
            return function() {
                triggerEscape();

                return _parent.call(this, ...arguments);
            };
        })(props.onEscape || function() {});

        const component = zoidApp(props);

        component.render = (function(_parent) {
            return function(container, context) {
                if (context === 'modal') {
                    // Maintain the parent contract of returning a ZalgoPromise
                    return new ZalgoPromise(function(resolve, reject) {
                        renderModal({
                            title: title,
                            onShown: function() {
                                const container = '.bootbox-body';
                                document.querySelector(container).innerHTML = '';
                                _parent.call(component, container, 'iframe')
                                    .then(resolve)
                                    .catch(reject);
                            },
                            container: container,
                        });
                    });
                }

                return _parent.call(component, container, context);
            };
        })(component.render);

        return component;
    };
};

function onEscKeyUp(e) {
    if (e.key === 'Escape' || e.which === 27) {
        window.xprops.onEscape({ key: e.key, type: e.type, which: e.which });
    }
}
