import WidgetLoader from './widget-loader';

const loadWidget = WidgetLoader({
    tag: 'shipearly-dealer-locator',
    path: '/locators/dealers',
    title: 'Dealer Locator',
    props: {
        retailer_shipearly_id: {
            type: 'string',
            required: false,
            queryParam: true
        },
        retailer_account_id: {
            type: 'string',
            required: false,
            queryParam: true
        },
    }
});

export default function(props) {
    // Allow both number and numeric string values by converting to the latter
    if (props.retailer_shipearly_id) {
        props.retailer_shipearly_id = props.retailer_shipearly_id.toString()
    }

    return loadWidget(props);
};
