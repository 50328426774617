import '../scss/_widget-modal.scss';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/util';
import { dialog } from 'bootbox/bootbox';

const _saved = {};

export function renderModal(options) {
    const defaults = {
        title: '<!-- Empty -->',
        message: '<div class="loading-dialog"><div class="spinner-border" role="status"><span class="sr-only">Loading&hellip;</span></div></div>',
        onEscape: true,
        backdrop: true,
        closeButton: true,
        className: 'shipearly-modal',
        size: 'extra-large',
        buttons: {},
        centerVertical: true,
    };

    _saved.dialog = dialog(Object.assign({}, defaults, options));

    return _saved.dialog;
}

export function triggerEscape() {
    if (typeof _saved.dialog?.trigger === 'function') {
        _saved.dialog.trigger('escape.close.bb');
    }

    return _saved.dialog;
}
