import WidgetLoader from './widget-loader';

const allPropKeys = [
    'product_shipearly_id',
    'variant_shipearly_id',
    'product_id',
    'variant_id',
    'sku',
    'upc',
    // Deprecated in favour of variant_shipearly_id
    'shipearly_id',
];

const loadWidget = WidgetLoader({
    tag: 'shipearly-product-locator',
    path: '/locators/products',
    title: 'Product Locator',
    props: allPropKeys.reduce((acc, key) => {
        acc[key] = {
            type: 'string',
            required: false,
            queryParam: true,
        };

        return acc;
    }, {}),
});

export default function(props) {
    const numericPropKeys = [
        'product_shipearly_id',
        'variant_shipearly_id',
        'product_id',
        'variant_id',
        // Deprecated
        'shipearly_id',
    ];

    // Allow numeric strings by converting number args to strings
    numericPropKeys.forEach(key => {
        if (props[key]) {
            props[key] = props[key].toString();
        }
    });

    return loadWidget(props);
};
