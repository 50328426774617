import DealerLocator from './js/dealer-locator';
import ProductLocator from './js/product-locator';

const VERSION = __VERSION__;

export {
    DealerLocator,
    ProductLocator,
    VERSION,
};
